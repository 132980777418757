import styled from 'styled-components';
import { BaseInput } from '~/components/forms/fields';
import {
  Card,
  Container,
  Typography,
  TextField,
  Button,
} from '@material-ui/core';

export const PageTitle = styled(Typography).attrs({ variant: 'h4' })`
  ${props => props.theme.breakpoints.down('sm')} {
    font-size: 32px;
  }
`;

export const Paragraph = styled(Typography)`
  margin: 1rem 0;
`;

export const ResetButton = styled(Button).attrs({
  size: 'small',
  color: 'primary',
  variant: 'contained',
})`
  text-transform: none;
`;

export const FormCard = styled(Card).attrs({ elevation: 1 })`
  padding: 0 1em 2em 1em;
`;

export const CenteredContainer = styled(Container).attrs({
  maxWidth: 'md',
  fixed: true,
})`
  padding: 2rem 0;
`;

export const SubmitButton = styled(Button).attrs({
  size: 'large',
  color: 'primary',
  variant: 'contained',
})`
  font-weight: bold;
  text-transform: none;
`;

export const EmailInput: typeof TextField = styled(BaseInput).attrs({
  variant: 'outlined',
  type: 'email',
})``;
