import { useForm, RegisterOptions, UseFormMethods } from 'react-hook-form';
import { emailPattern } from '~/common/regex';

export interface ForgotPasswordForm {
  email: string;
}

interface UseForgotPasswordForm {
  form: UseFormMethods<ForgotPasswordForm>;
  validationSchema: Map<keyof ForgotPasswordForm, RegisterOptions>;
}

export const useForgotPasswordForm = (): UseForgotPasswordForm => {
  const form = useForm<ForgotPasswordForm>({
    defaultValues: {
      email: '',
    },
  });

  const validationSchema = new Map<keyof ForgotPasswordForm, RegisterOptions>();
  validationSchema.set('email', { required: true, pattern: emailPattern });

  return { form, validationSchema };
};
