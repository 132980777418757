import React from 'react';
import { PageProps } from 'gatsby';
import SEO from '~/components/seo';
import { ForgotPasswordPage } from '~/components/ForgotPassword';

const ForgotPassword: React.FC<PageProps> = props => (
  <>
    <SEO
      pageContext={props.pageContext}
      title="Account.forgotPassword.pageTitle"
    />
    <ForgotPasswordPage {...props} />
  </>
);

export default ForgotPassword;
