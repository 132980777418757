import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { handleForgotPassword } from '~/requests/user';
import { useSnackbar } from '~/state/hooks/useSnackbar';
import { CardContent, CardActions, CircularProgress } from '@material-ui/core';
import {
  useForgotPasswordForm,
  ForgotPasswordForm,
} from './useForgotPasswordForm';
import * as Styled from './styles';

export const ForgotPasswordResetForm: React.FC = () => {
  const { t } = useTranslation();
  const { showSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const { form, validationSchema } = useForgotPasswordForm();

  const submitForm = async ({ email }: ForgotPasswordForm) => {
    setLoading(true);
    try {
      await handleForgotPassword(email);
      setEmailSent(true);
    } catch (error) {
      const errorMsg =
        error.code === 'LimitExceededException' ||
        error.code === 'TooManyRequestsException'
          ? t('Account.forgotPassword.limitExceededError')
          : t('Account.forgotPassword.error');
      showSnackbar(errorMsg, 'error');
    } finally {
      setLoading(false);
    }
  };

  const getErrorMessage = (
    fieldName: keyof ForgotPasswordForm,
    errType?: string
  ): string =>
    errType ? t(`Account.forgotPassword.${fieldName}.errors.${errType}`) : '';

  return emailSent ? (
    <Styled.FormCard>
      <CardContent>
        <Styled.Paragraph>
          {t('Account.forgotPassword.thankyou')}
        </Styled.Paragraph>
        <Styled.Paragraph>
          {t('Account.forgotPassword.success')}
        </Styled.Paragraph>
        <Styled.Paragraph>
          <Styled.ResetButton onClick={() => setEmailSent(false)}>
            {t('Account.resetPassword.requestAnother')}
          </Styled.ResetButton>
        </Styled.Paragraph>
      </CardContent>
    </Styled.FormCard>
  ) : (
    <Styled.FormCard>
      <CardContent>
        <Styled.Paragraph>
          {t('Account.forgotPassword.description1')}
        </Styled.Paragraph>
        <Styled.Paragraph>
          {t('Account.forgotPassword.description2')}
        </Styled.Paragraph>
        <Styled.EmailInput
          name="email"
          data-test="forgot_password_email_input"
          error={!!form.errors.email}
          label={t('Account.forgotPassword.email.label')}
          inputRef={form.register(validationSchema.get('email'))}
          helperText={getErrorMessage('email', form.errors?.email?.type)}
        />
      </CardContent>
      <CardActions>
        <Styled.SubmitButton
          type="submit"
          onClick={form.handleSubmit(submitForm)}
          endIcon={loading && <CircularProgress color="inherit" size={18} />}
        >
          {t('Account.forgotPassword.submitButton')}
        </Styled.SubmitButton>
      </CardActions>
    </Styled.FormCard>
  );
};
