import React from 'react';
import { Trans } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { ForgotPasswordResetForm } from './Form';
import { PageTitle, CenteredContainer } from './styles';
import { PageShell } from '~/components/BaseElements/pageShell';
import { PageProps } from 'gatsby';

export const ForgotPasswordPage: React.FC<PageProps> = () => (
  <PageShell>
    <CenteredContainer>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item xs={12} md={8}>
          <PageTitle>
            <Trans i18nKey="Account.forgotPassword.pageTitle" />
          </PageTitle>
        </Grid>
        <Grid item xs={12} md={8}>
          <ForgotPasswordResetForm />
        </Grid>
      </Grid>
    </CenteredContainer>
  </PageShell>
);
